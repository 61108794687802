html, body {
  height: 100%;
}

.page-content {
  height: 100%;
  width: 100%;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-red {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}

.card-body {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.card-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}